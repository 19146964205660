<template>
    <div class="login-page">
        <div class="form-content">
            <div class="login-box">
                <form @submit.prevent="login" method="post">
                    <div class="form-group-login">
                        <h2>
                            {{ $t('login.title') }}
                        </h2>
                    </div>

                    <div class="input-login-group-tbf" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error}">
                        <div class="label-input">
                            <label>{{ $t('general.email') }}</label>
                            <div class="error-msg" v-if="$v.email.$error || error">{{ $t('login.login-error') }}</div>
                        </div>

                        <div class="input-box">
                            <div class="icon-left"><email-icon /></div>
                            <input type="email" :placeholder="$t('login.email-placeholder')" class="input-text" v-model="email">
                        </div>
                    </div>
                    <div class="input-login-group-tbf" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error}">
                        <div class="label-input">
                            <label>{{ $t('login.password') }}</label>
                        </div>

                        <div class="input-box">
                            <div class="icon-left"><password-icon /></div>
                            <input type="password" :placeholder="$t('login.password-placeholder')" class="input-text" v-model="password">
                        </div>
                    </div>

                    <div class="form-group-login">
                        <div class="forgot">
                            <!-- <router-link :to="{path: '/register'}" class="go-register">{{ $t('login.no-account') }}</router-link> -->
                            <a href="https://central.tbf.ro/price" class="go-register">{{ $t('login.no-account') }}</a>
                            <router-link :to="{path: '/password/forgot' }" class="forgot-password">{{ $t('login.forgot-password') }}</router-link>
                        </div>
                    </div>
                    <div class="action-form">
                        <button id="buttonLogin" type="submit" class="btn-tbf blue">
                            <div class="loader"></div>
                            <div class="text">{{ $t('login.login-button') }}</div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="flappy-deleg" v-if="$resize && $mq.above(770)">
            <div class="preview_game" @click="viewGame" v-if="!view_game">
                <div id="sky" class="animated d-flex align-items-center">
                    <lottie class="bird-login active" :options="defaultOptionsLogin" :height="220" :width="220" v-on:animCreated="handleAnimationLogin"/>
                </div>
                <div id="land" class="animated"><div id="debug"></div></div>
            </div>
            <div id="gamecontainer" v-else>
                <div id="gamescreen">
                    <div id="sky" class="animated">
                        <div id="flyarea">
                            <div id="ceiling" class="animated"></div>
                            <!-- This is the flying and pipe area container -->
                            <div id="player" class="bird animated"></div>

                            <div id="bigscore"></div>

                            <div id="splash"></div>

                            <div id="scoreboard">
                                <div id="medal"></div>
                                <div id="currentscore"></div>
                                <div id="highscore"></div>
                                <div id="replay"><img src="/build/flappy/play.svg" alt="replay"></div>
                            </div>

                            <!-- Pipes go here! -->
                        </div>
                    </div>
                    <div id="land" class="animated"><div id="debug"></div></div>
                </div>
            </div>
            <div class="boundingbox" id="playerbox"></div>
            <div class="boundingbox" id="pipebox"></div>
        </div>
    </div>
</template>

<script>
    import '../../libraries/jquery.transit.min.js'
    window.buzz = require('../../libraries/buzz.min.js')
    import '../../libraries/flappy.js'

    import Lottie from 'vue-lottie';
    import * as animationDataLogin from '../../assets/animations/tbfdigital_login.json';

    import EmailIcon from '../Icons/Email';
    import PasswordIcon from '../Icons/Password';

    import { required, email } from 'vuelidate/lib/validators'

    export default {
        data(){
            return {
                error: false,
                password: '',
                email: '',
                defaultOptionsLogin: {
                    animationData: animationDataLogin.default,
                    autoplay: true,
                    loop: true,
                },
                view_game: false,
                options: [
                    { title: 'Romana', img: '/build/images/ro.png' },
                    { title: 'Español', img: '/build/images/es.png' },
                ]
            }
        },
        components: {
            Lottie,
            EmailIcon,
            PasswordIcon,
        },
        validations: {
            email: {required, email},
            password: {required}
        },
        methods: {
            login(){
                this.error = false
                var buttonName = 'buttonLogin'
                var btnSubmit = document.getElementById(buttonName);
                btnSubmit.disabled = true
                
                var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
                var btnSubmitText = document.querySelector(`#${buttonName} .text`)

                btnSubmit.classList.add('loading')
                btnSubmitLoader.classList.add('onProgress')
                btnSubmitText.innerHTML = this.$t('btn-submit.loading')

                var app = this
                this.$auth.login({
                    data: {
                        email: app.email, password: app.password
                    },
                    fetchUser: true,
                    staySignedIn: true
                })
                .then(null, (res) => {
                    let response = res.response.data
                    if(response.status == 'error'){
                        btnSubmitLoader.classList.add('finish')
                        setTimeout(()=>{
                            btnSubmitText.innerHTML = this.$t('btn-submit.error')
                            btnSubmit.classList.add('error')
                            btnSubmitLoader.classList.remove('onProgress', 'finish')
                            btnSubmit.classList.remove('loading')
                            setTimeout(()=>{
                                btnSubmit.classList.remove('error')
                                this.error = true
                                btnSubmitText.innerHTML = this.$t('login.login-button')
                            }, 1000)
                        }, 300)
                    }
                })
                .finally(() => {
                    setTimeout(()=>{
                        btnSubmit.disabled = false
                    }, 1500)
                })
            },
            handleAnimationLogin(anim){
                this.anim_login = anim;
                this.anim_login.setSpeed(1);
            },
            viewGame(){
                $('.bird-login').removeClass('active');
                setTimeout(() => {
                    this.view_game = true;
                    setTimeout(() => {
                        game_start();
                    },0)
                },300)
            }
        }
    }
</script>